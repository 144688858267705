import { useUser } from "@auth0/nextjs-auth0/client";
import PropTypes from "prop-types";
import React from "react";

const AuthWrapper = ({ children, renderOnUserSession = false }) => {
  const { error, isLoading, user } = useUser();

  const hasSession = !error && !isLoading && !!user;

  if (
    (renderOnUserSession && hasSession) ||
    (!renderOnUserSession && !hasSession)
  ) {
    return children;
  }

  return <div className="auth-wrapper-empty d-none"></div>;
};

AuthWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  renderOnUserSession: PropTypes.bool,
};

export default AuthWrapper;
